import qs from "qs";

import { IGetClientsQuery } from "../types/clients";
import { IFilter, ISortBy } from "../types/common";
import { IGetInviteTokensQuery, IGetModulesDictionaryQuery } from "../types/inviteTokens";
import { IGetProjectsQuery } from "../types/projects";
import { IGetUsersQuery } from "../types/users";

export const withPagination = (pageIndex: number, pageSize: number) => {
    return {
        page: pageIndex,
        limit: pageSize,
    };
};

export const withGlobalFilter = (global: string) => {
    return {
        global: global,
    };
};

export const withFilters = (filters?: IFilter[]) => {
    return {
        filters,
    };
};

export const withSortBy = (sortBy?: ISortBy[]) => {
    return {
        ...sortBy?.[0] && {
            sort: sortBy[0].id,
            desc: sortBy[0].desc ? "desc" : "asc",
        },
    };
};

export const getUsersQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    globalFilter = "",
    roles = undefined,
    projectId = undefined,
    organizationId = undefined,
}: IGetUsersQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withGlobalFilter(globalFilter),
        ...roles !== undefined && {
            roles,
        },
        ...projectId !== undefined && {
            projectId,
        },
        ...organizationId !== undefined && {
            organizationId,
        },
    }, { encode: false });
};

export const getClientsQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    globalFilter = "",
    projectId = undefined,
    assessment = undefined,
    personnelId = undefined,
}: IGetClientsQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withGlobalFilter(globalFilter),
        ...projectId !== undefined && {
            projectId,
        },
        ...assessment !== undefined && {
            assessment,
        },
        ...personnelId !== undefined && {
            personnelId,
        },
        ...projectId === null && {
            projectId: "null",
        },
    }, { encode: false });
};

export const getProjectsQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    globalFilter = "",
    organizationId = undefined,
}: IGetProjectsQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withGlobalFilter(globalFilter),
        ...organizationId !== undefined && {
            organizationId,
        },
    }, { encode: false });
};

export const getOrganizationsQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    globalFilter = "",
}: IGetProjectsQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withGlobalFilter(globalFilter),
    }, { encode: false });
};

export const getInviteTokensQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    globalFilter = "",
    projectId = undefined,
}: IGetInviteTokensQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withGlobalFilter(globalFilter),
        ...projectId !== undefined && {
            projectId,
        },
    }, { encode: false });
};
export const getModulesDictionaryQueryString = ({
    app,
    lang,
}: IGetModulesDictionaryQuery) => {
    return qs.stringify({
        app,
        lang,
    }, { encode: false });
};
